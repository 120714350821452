.container-buscando{
    
    /* height: 100vh; */
    width: 100%!important;
    margin: 0;
    display: flex!important;
    justify-content: center;
    align-items: center;

}

.container-registrador{
    width: 500px;
}

.colOpcaoAdicionalProduto{
    text-align: center;
}
.MuiDialog-paperFullScreen{
    background-color: #f4f4f4!important;
  }
  
  .valorFinalizarPedido{
    background: white;
    color: #28a745;
    padding: 0 10px;
    margin-left: 10px;
    border-radius: 12px;
}
  .desconto-ativado{
    color:#28a745;
  }
  .desconto-desativado{
    color:#dc3545;
  }
  .colorSuccess{
      order: 1;
  }
  .colorDanger{
      order: 2;
  }

  .colorSuccess p{
      color:#28a745;
  }
  .colorDanger p{
      color:#dc3545;
  }

img.bandeiraCartao {
    max-width: 50px;
}
.produtoDeGrid {
    /* margin-top: 15px; */
    /* border: 10px solid #ffffff; */
    background-size: contain!important;
    height: 100px;
    margin: 1em 0.5em 0 0.5em;
}


.CardProduto{
    text-align: center;
}

.CardProdutoCol .descricaoProduto{
    text-transform: lowercase;
}

.CardProdutoCol {
    margin: 0.5em 0 0 0;;
}

hr{
    margin: 0.5em 0!important;
}

#Impressora-P-Imprimir{
    padding: 10px;
}

@media only screen and (max-width: 600px) {
    .CardProdutoCol{
        padding: 0.5em;
    }
    .container-registrador{
        width: auto;
    }
  }

