.login{
    text-align: center;
}

#select-status-estabelecimento{
    padding-left: 3em;
    padding-right: 0;
}

.divisaoEstabelecimentos{
    width: 100%; 
    margin-bottom: 1em!important;
}

.motivoCancelamentoTexto{
    margin-top: 1em;
    margin-bottom: 1em;
}

#versao-atual{
    POSITION: ABSOLUTE;
    top: 2px;
    right: 5px;
    opacity: 0.3;
    font-weight: 500;
}

.totalN{
    position: relative;
    float: right;
    background-color: #dc3545;
    color: white;
    padding: 0 10px;
    border-radius: 11px;
    margin: 0 5px;
}
.totalP{
    position: relative;
    float: right;
    background-color: white;
    color: #51a351;
    padding: 0 10px;
    border-radius: 11px;
    margin: 0 5px;
}

hr{
    margin: 0.5em 0!important;
}

.quantidadeQuarto{
    background: white;
    color: #51a351;
    /* font-weight: 500; */
    padding: 10px;
    border-radius: 100%;
}
.quantidadeTerceiro{
    background: white;
    color: #0080ff;
    /* font-weight: 500; */
    padding: 10px;
    border-radius: 100%;
}
.quantidadeSegundo{
    background: white;
    color: #fd7e14;
    /* font-weight: 500; */
    padding: 10px;
    border-radius: 100%;
}
.quantidadePrimeiro{
    background: white;
    color: #f0bb1f;
    /* font-weight: 500; */
    padding: 10px;
    border-radius: 100%;
}

#rootPrint{
    display: none;
}
#root{
    display: block;
}

.opcaoTop{
    margin: 5px 0!important;
    color: #5f5f5f!important;
}

/* .opcoesTop{
    display: inline-flex;
}

.opcaoTop{
    margin: 0 5px!important;
} */

.distanciaTop{
    margin-top: 6em;
    /* margin-top: 0em; */
}

@media only screen and (max-width: 600px) {
    .distanciaTop{
        margin-top: 6em;
        /* margin-top: 0em; */
    }
    .m-p0{
        order: 0;
    }
    .m-p0{
        order: 1;
    }
    .opcoesTop {
      display: block;
    }
    .opcaoTop{
        margin: 5px 0!important;
        color: #5f5f5f!important;
    }

    .totalN{
        position: relative;
        float: right;
        background-color: #dc3545;
        color: white;
        padding: 0 5px;
        border-radius: 10px;
        margin: 0 2px;
    }
    .totalP{
        position: relative;
        float: right;
        background-color: white;
        color: #51a351;
        padding: 0 5px;
        border-radius: 10px;
        margin: 0 2px;
    }


  }


@media print {
    #rootPrint{
        display: block;
        left: 10px;
        z-index: -1;
        font-size: 18px;
        line-height: 23px;
        color: black!important;
        font-weight: 800;
    }
    #rootPrint.textoImpressaoGrande {
        font-size: 22px;
    }
    #root{
        display: none;
    }
    div[role*="presentation"]{
        display: none;
    }
}

#status-delivery-alternativo {
    padding: 10px!important;
}
#status-delivery{
    transform: translate(14px, 12px) scale(1);
}

#status-delivery.MuiInputLabel-shrink{
    transform: translate(14px, -6px) scale(0.75);
    background-color: white;
}

.tipoEntrega{
    width: 17px!important;
    height: 17px!important;
}

.statusSegundo{
    
    border-radius: 10px;
    color: white;
    background-color: #fd7e14;
    padding: 2px 10px;

}

.statusTerceiro{
    
    border-radius: 10px;
    color: white;
    background-color: #0080ff;
    padding: 2px 10px;

}

.statusQuarto{
    order: 1;
    border-radius: 10px;
    color: white;
    background-color: #51a351;
    padding: 2px 10px;
}
.statusQuartoVermelho{
    order: 2;
    border-radius: 10px;
    color: white;
    background-color: #dc3545;
    padding: 2px 10px;

}