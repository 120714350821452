@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');


* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

.spanFormasDePagamento .MuiFormControlLabel-root{
  margin: 0!important;
}


#groupFormaDePagamento .MuiListItem-button{
  padding: 0;
}


.naoArredondado{
  border-radius: 0!important;
  margin: 0!important;
  /* border:0.5px solid #737373!important */
  /* padding: 0!important; */
}
.botaoVermelho{
  background: #dc3545!important;
  color: white!important;
  width: 100%;
}
.botaoVerde{
  background: #28a745!important;
  color: white!important;
  width: 100%;
}

.botaoCinza{
  background: #6c757d!important;
  color: white!important;
  width: 100%;
}

.newCard{
  left: 2em;
  position: absolute;
  top: 2em;
  padding: 2px 10px;
  background: #dc3545;
  border-radius: 10px;
  color: white;
  font-size: 10px;
  z-index: 1;
}

.setaVoltarModal{
  color: black!important;
  margin-right: 6px!important;
  transform: rotate(180deg);
  padding: 0 0 0 10px!important;
}
body {
  font: 400 14px Roboto, sans-serif;
  background-color: #f4f4f4!important;
  -webkit-font-smoothing: antialiased;
  /*background-image: url('https://clientefielsp.s3-sa-east-1.amazonaws.com/site/patterns/patternRestaurante');*/   
}

#cabecalhoApp{
  left: 0;
}
.Loading{
  text-align: center;
}

/* .MuiDialog-root{
  z-index: 1300!important;
} */

/* .MuiBackdrop-root{
  z-index: 1301!important;
} */

button:focus{
  outline-style: none!important;
}

.pretoEBranco{
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
}

.divImageCentro{
    width: 100vw;
    height: 100vh;
    display: table-cell;
    vertical-align: middle;
    background: white;
    text-align: center;
}

.divImageCentro img{
  opacity: 0.3;
}



.divImageCentroCardapio{
    width: 100vw;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.divImageCentroCardapio img{
  width: 100%;
  max-width: 350px;
  opacity: 0.3;
}