.colCardServico{
    margin: 0.5em 0;
}

.pretoEBranco{
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray; /* IE */
}

.helpCard{
    position: absolute;
    right: 1em;
    top:1em;
    color: #ea9b09; 
    z-index: 2;
}


.detalhesUsuario{
    text-align: left;
}

.detalhesUsuarioDesconnect{
    text-align: right;
}

@media only screen and (max-width: 600px) {


    .detalhesUsuario{
        text-align: center;
    }
    
    .detalhesUsuarioDesconnect{
        text-align: center;
    }

}